exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-archive-js": () => import("./../../../src/pages/archive.js" /* webpackChunkName: "component---src-pages-archive-js" */),
  "component---src-pages-archive-markdown-remark-frontmatter-slug-js": () => import("./../../../src/pages/archive/{MarkdownRemark.frontmatter__slug}.js" /* webpackChunkName: "component---src-pages-archive-markdown-remark-frontmatter-slug-js" */),
  "component---src-pages-bookmarks-js": () => import("./../../../src/pages/bookmarks.js" /* webpackChunkName: "component---src-pages-bookmarks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

